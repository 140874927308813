import moment from "moment";
import { navigate } from "gatsby";

const WARN_MINUTES_BEFORE_LOGOUT = 5;

export const autoLogout = () => {
  const signInDateTime = moment(sessionStorage.getItem("signInDateTime"));
  const signOutDateTime = moment(sessionStorage.getItem("signOutDateTime"));

  const currentDateTime = moment();

  if (signInDateTime) {
    const isPassedSessionTime = currentDateTime.isAfter(signOutDateTime);

    if (isPassedSessionTime) {
      navigate("/login");
      sessionStorage.clear();
    }
  }
};

export const getTimeRemaining = () => {
  const timeNow = moment();
  const signOutDateTime = moment(sessionStorage.getItem("signOutDateTime"));

  const timeBeforeAutoLogout = moment.duration(signOutDateTime.diff(timeNow));

  const timeRemainingInMinutes = timeBeforeAutoLogout.asMinutes().toFixed(0);
  const timeRemainingInSeconds = timeBeforeAutoLogout.asSeconds().toFixed(0);

  return { timeRemainingInMinutes, timeRemainingInSeconds };
};

export const isNearAutoLogout = () => {
  const { timeRemainingInMinutes } = getTimeRemaining();
  if (timeRemainingInMinutes <= WARN_MINUTES_BEFORE_LOGOUT) return true;

  return false;
};
