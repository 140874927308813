import * as Yup from "yup";

const REQUIRED_MESSAGE = "This field is required.";
const REQUIRED_QUANTITY = "Please enter quantity.";
const VALID_DATE = "Please enter a valid date.";
const VALID_EMAIL = "Please enter a valid email address.";
const VALID_MOBILE_NUMBER =
  "Please enter a valid mobile number in this format: 09991234567";
const VALID_LICENSE_NUMBER = "Please follow this format: 0012345";

export const AddConsultationValidationSchema = Yup.object().shape({
  employeeName: Yup.string().required(REQUIRED_MESSAGE),
  consultType: Yup.string().required(REQUIRED_MESSAGE),
  status: Yup.string().required(REQUIRED_MESSAGE),
  employeeSite: Yup.string().when("consultType", {
    is: "Teleconsult",
    then: Yup.string().required(REQUIRED_MESSAGE),
    otherwise: Yup.string().nullable(),
  }),
  recommendation: Yup.string().required(REQUIRED_MESSAGE),
  chiefComplaint: Yup.array()
    .of(
      Yup.object().shape({
        description: Yup.string().required(REQUIRED_MESSAGE),
      })
    )
    .required(REQUIRED_MESSAGE),
  nurseAssessment: Yup.string().required(REQUIRED_MESSAGE),
  medstaffEmail: Yup.string()
    .email(VALID_EMAIL)
    .required(REQUIRED_MESSAGE),

  scheduled: Yup.date()
    .typeError(VALID_DATE)
    .nullable(),
  logs: Yup.array().of(
    Yup.object().shape({
      hasMedicines: Yup.boolean(),
      product: Yup.string(),
      quantity: Yup.number()
        .when("hasMedicines", {
          is: true,
          then: Yup.number()
            .required(REQUIRED_QUANTITY)
            .min(0, REQUIRED_QUANTITY),
        })
        .typeError(REQUIRED_QUANTITY),
    })
  ),
});

export const EditConsultationValidationSchema = Yup.object().shape({
  clinicStaff: Yup.array().required(REQUIRED_MESSAGE),
  recommendation: Yup.string().required(REQUIRED_MESSAGE),
  chiefComplaint: Yup.array()
    .of(
      Yup.object().shape({
        description: Yup.string().required(REQUIRED_MESSAGE),
      })
    )
    .required(REQUIRED_MESSAGE),
  nurseAssessment: Yup.string().required(REQUIRED_MESSAGE),
  consultType: Yup.string().required(REQUIRED_MESSAGE),
  status: Yup.string().required(REQUIRED_MESSAGE),
  scheduled: Yup.date()
    .typeError(VALID_DATE)
    .nullable(),
});

export const DoctorValidationSchema = Yup.object().shape({
  lastName: Yup.string().required(REQUIRED_MESSAGE),
  firstName: Yup.string().required(REQUIRED_MESSAGE),
  licenseNumber: Yup.string()
    .min(5, VALID_LICENSE_NUMBER)
    .max(7, VALID_LICENSE_NUMBER)
    .matches(/^[0-9]*$/, VALID_LICENSE_NUMBER)
    .required(REQUIRED_MESSAGE),
  contactNumber: Yup.string()
    .min(11, VALID_MOBILE_NUMBER)
    .matches(/^[0-9]*$/, VALID_MOBILE_NUMBER),
});

export const EmployeeValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_MESSAGE),
  lastName: Yup.string().required(REQUIRED_MESSAGE),
  // sex: Yup.string().required(REQUIRED_MESSAGE),
  // contactNumber: Yup.string().required(REQUIRED_MESSAGE),
  // email: Yup.string().required(REQUIRED_MESSAGE),
  // employee: Yup.object().shape({
  //   position: Yup.string().required(REQUIRED_MESSAGE),
  //   department: Yup.string().required(REQUIRED_MESSAGE),
  //   employeeNumber: Yup.string().required(REQUIRED_MESSAGE),
  //   birthday: Yup.string().required(REQUIRED_MESSAGE),
  // }),
});

export const SupervisorValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_MESSAGE),
  lastName: Yup.string().required(REQUIRED_MESSAGE),
  // sex: Yup.string().required(REQUIRED_MESSAGE),
  contactNumber: Yup.string().required(REQUIRED_MESSAGE),
  email: Yup.string().required(REQUIRED_MESSAGE),
  employee: Yup.object().shape({
    position: Yup.string().required(REQUIRED_MESSAGE),
    department: Yup.string().required(REQUIRED_MESSAGE),
    employeeNumber: Yup.string().required(REQUIRED_MESSAGE),
    // birthday: Yup.string().required(REQUIRED_MESSAGE),
  }),
});
export const MedcertValidationSchema = Yup.object().shape({
  employee: Yup.string().required(REQUIRED_MESSAGE),
  dateIssued: Yup.date().required(REQUIRED_MESSAGE),
  dateReceived: Yup.date().required(REQUIRED_MESSAGE),
  supervisorName: Yup.string().required(REQUIRED_MESSAGE),
  hospitalName: Yup.string().required(REQUIRED_MESSAGE),
  attendingPhysician: Yup.string().required(REQUIRED_MESSAGE),
  diagnosis: Yup.string().required(REQUIRED_MESSAGE),
  receivedBy: Yup.string().required(REQUIRED_MESSAGE),
  purpose: Yup.string().required(REQUIRED_MESSAGE),
  hasCustomFields: Yup.boolean(),
  copy_received: Yup.string().when("hasCustomFields", {
    is: true,
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  validation_method: Yup.string().when("hasCustomFields", {
    is: true,
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
});

export const LoginValidationSchema = Yup.object().shape({
  Username: Yup.string().email(VALID_EMAIL),
});

export const InventoryValidationSchema = Yup.object().shape({
  Quantity: Yup.number().required("Please enter quantity."),
  reason: Yup.string()
    .matches(/(replenished|adjusted|expired|damaged)/)
    .required(REQUIRED_MESSAGE),
});

export const IncidentReportValidationSchema = Yup.object().shape({
  reportType: Yup.string().required(REQUIRED_MESSAGE),
  dateSentToMg: Yup.date().required(REQUIRED_MESSAGE),
  complainant: Yup.string().required(REQUIRED_MESSAGE),
  primaryCategory: Yup.string().required(REQUIRED_MESSAGE),
  complaintDateTime: Yup.date().required(REQUIRED_MESSAGE),
  fullComplaint: Yup.string().required(REQUIRED_MESSAGE),
  clinic: Yup.number().required(REQUIRED_MESSAGE),
  clinicStaffOnDuty: Yup.array().required(REQUIRED_MESSAGE),
  // dateSentToChsi: Yup.date().required(REQUIRED_MESSAGE),
  // dateChsiRepliedToMg: Yup.date().required(REQUIRED_MESSAGE),
  // dateRepliedToTp: Yup.date().required(REQUIRED_MESSAGE),
  // responseFromEmployee: Yup.string().required(REQUIRED_MESSAGE),
  // responseFromTp: Yup.string().required(REQUIRED_MESSAGE),
  status: Yup.string().required(REQUIRED_MESSAGE),
});
