/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, {
  Fragment,
  useEffect,
  useContext,
  useState,
  useCallback,
  useRef,
} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";
import { StaticQuery, graphql, navigate, Link } from "gatsby";

// import ThemeContext from "../context/ThemeContext"
import AppContext from "../context/AppContext";
import Navbar from "./Navbar";
import SessionReminderModal from "./SessionReminderModal";
import SessionReminderBanner from "./SessionReminderBanner";

import styles from "./layout.module.scss";
import Login from "../pages/login";
import { Auth } from "aws-amplify";

import api from "../services/api";
import Banner from "./Banner";
import Container from "./Container";
import { autoLogout, isNearAutoLogout } from "../services/autoLogout";

export const PureLayout = ({
  data,
  hasSidebar,
  children,
  sidebarContent,
  pageTitle,
  hasRightButton,
  overdueMedcerts,
}) => {
  return (
    <Fragment>
      <nav className="navbar is-primary is-fixed-top" />
      <div className="mb-5">
        <SessionReminderBanner overdueMedcerts={overdueMedcerts} />
      </div>
      <Container
        desktop={hasSidebar ? 12 : 10}
        widescreen={hasSidebar ? 10 : 8}
        isCentered
      >
        <div
          className={classNames(
            { [styles.paddingWithSidebar]: hasSidebar },
            "container is-fluid"
          )}
        >
          <div className="columns is-mobile my-2">
            <div className="column">
              <h3 className={classNames("title ml-0 mt-2", styles.pageTitle)}>
                {pageTitle}
              </h3>
            </div>
            <div className="column is-narrow mt-2">{hasRightButton}</div>
          </div>

          {/* {hasBanner && <Banner />} */}
          {children}
        </div>
        {hasSidebar && (
          <section className={styles.sidebar}>{sidebarContent}</section>
        )}
      </Container>
    </Fragment>
  );
};

const Layout = props => {
  const [overdueMedcerts, setOverdueMedcerts] = useState(0);

  const appContext = useContext(AppContext);

  const trackTimeRemaining = useRef(null);
  // const [token, setToken] = useState('')
  let token, clinicId, clinic;

  if (typeof window !== `undefined`) {
    token = window.sessionStorage.getItem("loginToken");
    clinicId = window.sessionStorage.getItem("clinicId");
    clinic = window.sessionStorage.getItem("clinic")
      ? JSON.parse(window.sessionStorage.getItem("clinic"))
      : {};
  }

  useEffect(() => {
    if (!!clinicId) {
      api
        .get(
          `medcerts/report/?clinic=${clinicId}&status=Pending&isOverdue=true`
        )
        .then(response => {
          setOverdueMedcerts(response.data.count);
        });
    }
    Auth.currentSession().then(data => {
      if (
        data.getAccessToken().getJwtToken() !==
        sessionStorage.getItem("loginToken")
      ) {
        sessionStorage.setItem(
          "loginToken",
          data.getAccessToken().getJwtToken()
        );
      }
    });
    if (!appContext.userDetails.firstName) {
      api
        .post(
          "profiles/fetch_user/",
          {
            employee_id: sessionStorage.getItem("employeeId"),
          },
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        )
        .then(response => {
          sessionStorage.setItem(
            "headcount",
            response.data.results[0].employee.site.headCount
          );
          sessionStorage.setItem("staffId", response.data.staffId);
          sessionStorage.setItem("clinicName", response.data.clinic.name);
          sessionStorage.setItem(
            "clinicEmail",
            response.data.clinic.emailAddress
          );
          sessionStorage.setItem("siteName", response.data.clinic.site);
          sessionStorage.setItem("clinicId", response.data.clinic.id);
          sessionStorage.setItem("clientId", response.data.clinic.client.id);
          sessionStorage.setItem(
            "clientCode",
            response.data.clinic.client.code
          );
          sessionStorage.setItem(
            "teleconsults",
            response.data.clinic.acceptsTeleconsults
          );
          appContext.setUserDetails({
            ...response.data.results[0],
            acceptsTeleconsults: response.data.clinic.acceptsTeleconsults,
          });
        })
        .catch(error => {
          navigate("/login");
        });
    }
  });

  useEffect(() => {
    if (!!clinicId && !!clinic.tags && clinic.tags.includes("auto-logout")) {
      trackTimeRemaining.current = setInterval(() => {
        autoLogout();
        if (isNearAutoLogout()) {
          appContext.useModal(
            <SessionReminderModal />,
            "grey",
            "Log in for your next shift"
          );
        }
      }, 60000);
    }
    return () => {
      clearInterval(trackTimeRemaining.current);
    };
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <AppContext.Consumer>
          {app => {
            if (token)
              return (
                <PureLayout
                  overdueMedcerts={overdueMedcerts}
                  data={data}
                  {...props}
                >
                  <Navbar />
                  {props.children}
                </PureLayout>
              );
            else return <Login />;
          }}
        </AppContext.Consumer>
      )}
    />
  );
};

PureLayout.propTypes = {
  hasSidebar: PropTypes.bool,
  children: PropTypes.node,
  sidebarContent: PropTypes.node,
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
