import React from "react";
import Layout from "../../../layout/Layout";
import SEO from "../../../layout/Seo";
import AdminMedcerts from "../../../components/Admin/Medcerts/AdminMedcerts";

export default () => (
  <Layout pageTitle="Admin Medcerts">
    <SEO title="Admin Medcerts" />
    <AdminMedcerts />
  </Layout>
);
