import React, { useState, useEffect } from "react";

import { getTimeRemaining } from "../services/autoLogout";

const SessionReminderModal = () => {
  const { timeRemainingInSeconds } = getTimeRemaining();
  const [timeRemaining, setTimeRemaining] = useState(timeRemainingInSeconds);

  useEffect(() => {
    setTimeout(() => {
      setTimeRemaining(timeRemaining - 1);
    }, 1000);
  }, [timeRemaining]);

  const message =
    timeRemaining > 0
      ? `Please finish all pending work. You will be automatically logged out in ${timeRemaining} seconds and all unfinished work will be lost.`
      : "Your session has ended.";
  return <p>{message}</p>;
};
export default SessionReminderModal;
