import React, { useEffect, useState, Fragment } from "react";
import { StaticQuery, Link } from "gatsby";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";

import AppContext from "../context/AppContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserShield } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

export const NavbarItem = ({ route, label }) => (
  <Link
    to={route}
    className="navbar-item p-2"
    activeStyle={{ backgroundColor: "#0A5355", fontWeight: "bolder" }}
  >
    <span className="has-text-white">{label}</span>
  </Link>
);

export const NavDropdownItem = ({ route, label }) => (
  <Link
    className="navbar-item has-text-white-excluding-desktop"
    to={route}
    activeStyle={{ color: "#0A5355", fontWeight: "bolder" }}
  >
    {label}
  </Link>
);

export const NavDropdown = ({ children, label, icon }) => (
  <div className="navbar-item has-dropdown is-hoverable">
    <span className="navbar-link has-text-white">
      <FontAwesomeIcon icon={icon} className="has-text-white mr-1" />
      {label}
    </span>
    <div className="navbar-dropdown">{children}</div>
  </div>
);

export const AdminDropdown = () => (
  <NavDropdown label="Admin" icon={faUserShield}>
    <NavDropdownItem route="/admin/dashboard" label="Dashboard" />
    <NavDropdownItem route="/admin/consultations" label="Consultations" />
    <NavDropdownItem route="/admin/medcerts" label="Medcerts" />
    <NavDropdownItem route="/admin/inventory" label="Inventory" />
    <NavDropdownItem route="/admin/amr" label="AMR" />
    <NavDropdownItem route="/admin/incident-reports" label="Incident Reports" />
    <NavDropdownItem route="/admin/batch-upload" label="Batch Upload" />
  </NavDropdown>
);

export const LogoutItem = ({ route, label }) => (
  <>
    <AppContext.Consumer>
      {app => {
        return (
          <div className="navbar-item has-dropdown is-hoverable">
            <span className="navbar-link has-text-white">
              <FontAwesomeIcon icon={faUser} className="has-text-white mr-1" />
              {app.userDetails.firstName} {app.userDetails.lastName}
            </span>

            <div className="navbar-dropdown">
              <Link
                className="navbar-item has-background-danger-light has-text-danger"
                to={route}
                activeStyle={{
                  backgroundColor: "#0A5355",
                  fontWeight: "bolder",
                }}
              >
                {label}
              </Link>
            </div>
          </div>
        );
      }}
    </AppContext.Consumer>
  </>
);

export const PureNavbar = ({ data, children }) => {
  const [navbarIsActive, setNavbarIsActive] = useState(false);

  return (
    <nav
      className="navbar is-primary is-fixed-top"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container is-fluid">
        <div className="navbar-brand">
          <Link className="navbar-item pl-2" to="/">
            <Img fixed={data.mgLogo.childImageSharp.fixed} />
          </Link>
          <a
            role="button"
            className={classNames("navbar-burger", {
              "is-active": navbarIsActive,
            })}
            aria-label="menu"
            aria-expanded="false"
            onClick={() => setNavbarIsActive(prevState => !prevState)}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
        <div
          className={classNames("navbar-menu", {
            "is-active": navbarIsActive,
            "has-background-primary": navbarIsActive,
            "has-text-white": navbarIsActive,
          })}
        >
          {children}
        </div>
      </div>
    </nav>
  );
};

export const Navbar = () => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("veggie") === btoa("carrot")) setIsAdmin(true);
    else setIsAdmin(false);
  }, []);

  return (
    <AppContext.Consumer>
      {app => (
        <StaticQuery
          query={graphql`
            query {
              mgLogo: file(relativePath: { eq: "mg-logo.png" }) {
                childImageSharp {
                  fixed(width: 120) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          `}
          render={data => (
            <Fragment>
              <PureNavbar data={data}>
                <div className="navbar-start">
                  <span className="navbar-item has-text-weight-bold has-text-white">
                    <span role="img" aria-label="clinic" className="mr-1">
                      🏥
                    </span>{" "}
                    {app.siteDetails.name}
                  </span>
                </div>
                <div className="navbar-end">
                  <NavbarItem route="/" label="Dashboard" />
                  <NavbarItem route="/consultations" label="Consultations" />
                  <NavbarItem route="/inventory" label="Inventory" />
                  <NavbarItem route="/medcerts" label="MedCert Validation" />
                  {isAdmin && <AdminDropdown />}
                  <LogoutItem route="/login" label="Log Out" />
                </div>
              </PureNavbar>
            </Fragment>
          )}
        />
      )}
    </AppContext.Consumer>
  );
};

Navbar.propTypes = {
  children: PropTypes.node,
};

export default Navbar;
