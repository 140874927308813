import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";
import classNames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const Input = ({
  label,
  type,
  placeholder,
  min,
  defaultValue,
  name,
  step,
  isRequired,
  render,
  isDisabled,
  description,
  maxLength,
}) => {
  const propsObject = {
    className: "input",
    type,
    min,
    defaultValue,
    name,
    placeholder,
    step,
    disabled: isDisabled,
    maxLength,
  };
  return (
    <>
      <div className={classNames("field mb-2")}>
        {!!label && (
          <label
            className={classNames("label mt-1", {
              "has-text-weight-normal has-text-grey-dark": !isRequired,
            })}
          >
            {label}
            {!!isRequired && <span className="has-text-danger"> *</span>}
            {description && (
              <span
                className="icon has-text-grey-light mr-1 tooltip tooltip-text is-tooltip-right"
                data-tooltip={description}
              >
                <FontAwesomeIcon icon={faQuestionCircle} size="1x" />
              </span>
            )}
          </label>
        )}
        <div className="field-body">
          <div className="field">
            <div className="control">
              {render ? (
                <Field {...propsObject} render={props => render(props)} />
              ) : (
                <Field
                  {...propsObject}
                  onKeyDown={event => {
                    if (type === "number" && event.key === "e")
                      event.preventDefault();
                  }}
                  onPaste={event => {
                    if (
                      type === "number" &&
                      event.clipboardData.getData("text").includes("e")
                    )
                      event.preventDefault();
                  }}
                />
              )}
              <ErrorMessage name={name}>
                {error => (
                  <div className="has-text-danger is-size-7">{error}</div>
                )}
              </ErrorMessage>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const inputPropTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  min: PropTypes.number,
  defaultValue: PropTypes.value,
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
};

Input.propTypes = inputPropTypes;

export default Input;
