import React from "react";

const Banner = ({ message }) => {
  return (
    <div
      className="has-background-warning has-text-centered mt-1"
      style={{
        padding: "8px",
        position: "fixed",
        top: "47px",
        width: "100%",
        zIndex: 5,
        verticalAlign: "middle",
      }}
    >
      {message}
    </div>
  );
};

export default Banner;
