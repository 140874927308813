import React, { Fragment, useContext } from "react";
import { Location } from "@reach/router";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import classNames from "classnames";

import LoginForm from "../components/Login/LoginForm";
import AppContext from "../context/AppContext";
import SEO from "../layout/Seo";
import { login, topBackground } from "../components/Login/login.module.scss";

const Login = () => {
  const appContext = useContext(AppContext);

  return (
    <Fragment>
      <SEO title="Login" />
      <div className={classNames(topBackground)}>
        <div className="columns is-centered has-text-centered mt-5">
          <div className="column is-3-fullhd is-4-desktop is-6-tablet is-8-mobile">
            <div className={classNames(login)}>
              <div className="card-content p-3">
                <StaticQuery
                  query={graphql`
                    query {
                      mgLogo: file(relativePath: { eq: "mg-teal.png" }) {
                        childImageSharp {
                          fixed(width: 300) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                  `}
                  render={data => (
                    <Img fixed={data.mgLogo.childImageSharp.fixed} />
                  )}
                />
                <Location>
                  {({ location }) => {
                    return (
                      <LoginForm
                        initialValues={{
                          Username: "",
                          Password: "",
                          Code: "",
                          NewPassword: "",
                        }}
                        location={location}
                        appContext={appContext}
                      />
                    );
                  }}
                </Location>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
