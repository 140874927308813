import React, { useState, useEffect, Fragment } from "react";
import { Link } from "gatsby";

import { getTimeRemaining, isNearAutoLogout } from "../services/autoLogout";
import Banner from "./Banner";

const SessionReminderBanner = ({ overdueMedcerts }) => {
  const { timeRemainingInSeconds } = getTimeRemaining();
  const [timeRemaining, setTimeRemaining] = useState(timeRemainingInSeconds);

  useEffect(() => {
    setTimeout(() => {
      setTimeRemaining(timeRemaining - 1);
    }, 1000);
  }, [timeRemaining]);

  const message =
    timeRemaining > 0
      ? `Please finish all pending work. You will be automatically logged out in ${timeRemaining} seconds and all unfinished work will be lost.`
      : "Your session has ended.";

  switch (true) {
    case isNearAutoLogout():
      return <Banner message={message} />;
    case overdueMedcerts > 0:
      return (
        <Banner
          message={
            <Fragment>
              You have <b>{overdueMedcerts}</b> medcert
              {overdueMedcerts > 1 && "s"} that{" "}
              {overdueMedcerts > 1 ? "are" : "is"} pending for more than 40
              hours. <b>Please validate immediately.</b>{" "}
              <Link to="/medcerts">
                <button className="ml-1 button is-primary is-small">
                  Validate Medcert{overdueMedcerts > 1 && "s"}
                </button>
              </Link>
            </Fragment>
          }
        />
      );

    default:
      return null;
  }
};
export default SessionReminderBanner;
