import React, { useState, useEffect } from "react";
import moment from "moment";
import classNames from "classnames";
import Amplify, { Auth } from "aws-amplify";
import { navigate } from "gatsby";
import { Formik, Form } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import Input from "../Templates/Forms/Input";
import { LoginValidationSchema } from "../Templates/Forms/ValidationSchema";

import api from "../../services/api";
import awsExports from "../../services/awsExports";

Amplify.configure(awsExports);

const MAX_SESSION_HOURS_DURATION = 8;

const LoginForm = ({ initialValues, location, appContext, viewType }) => {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [view, setView] = useState(viewType || "logIn");
  const [Username, setUsername] = useState("");

  const handleSendCode = Username => {
    setLoading(true);
    setError(false);
    Auth.forgotPassword(Username)
      .then(data => {
        setLoading(false);
        setUsername(Username);
        setView("resetPassword");
      })
      .catch(error => {
        setLoading(false);
        setError(true);
        setErrorMessage(error.message);
      });
  };

  const handleResetPassword = values => {
    setLoading(true);
    setError(false);
    Auth.forgotPasswordSubmit(Username, values.Code, values.NewPassword)
      .then(data => {
        setLoading(false);
        setView("logIn");
      })
      .catch(error => {
        setLoading(false);
        setError(true);
        setErrorMessage(error.message);
      });
  };

  const storeTokens = user => {
    let accessToken = user.signInUserSession.accessToken.jwtToken;
    let refreshToken = user.signInUserSession.refreshToken.token;
    let userEmail = user.attributes.email;
    let groups =
      user.signInUserSession.accessToken.payload["cognito:groups"] || [];

    const signOutDateTime = moment().add(MAX_SESSION_HOURS_DURATION, "h");
    sessionStorage.setItem("signOutDateTime", signOutDateTime);

    sessionStorage.setItem("loginToken", accessToken);
    sessionStorage.setItem("refreshToken", refreshToken);
    sessionStorage.setItem("employeeId", userEmail);
    sessionStorage.setItem("signInDateTime", moment());
    sessionStorage.setItem("signOutDateTime", signOutDateTime);

    if (groups.includes("hq")) sessionStorage.setItem("veggie", btoa("carrot"));
  };

  const handleAWSLogin = (values, pathName) => {
    setError(false);
    setLoading(true);

    if (pathName === "/login") pathName = "/";
    if (values.Password === "" || values.Username === "") {
      setLoading(false);
      setError(true);
      setErrorMessage("Please enter your complete credentials.");
      return;
    }

    Auth.signIn({
      username: values.Username,
      password: values.Password,
    })
      .then(user => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          Auth.completeNewPassword(
            user, // the Cognito User Object
            values.Password, // the new password
            {
              email: values.Username,
            }
          )
            .then(user => {
              // at this time the user is logged in if no MFA required
              storeTokens(user);
            })
            .catch(e => {
              setLoading(false);
              setError(true);
              setErrorMessage(e.message);
            });
        } else {
          storeTokens(user);

          let userEmail = values.Username;

          let clinicId = "";

          api
            .post("profiles/fetch_user/", {
              employee_id: userEmail,
            })
            .then(response => {
              let userResults = response.data.results;

              userResults = userResults.reduce((currUser, nextUser) => {
                return moment(nextUser.created).diff(currUser.created) > 0
                  ? currUser
                  : nextUser;
              });

              sessionStorage.setItem(
                "headcount",
                userResults.employee.site.headCount
              );

              sessionStorage.setItem("staffId", response.data.staffId);
              // sessionStorage.setItem(
              //   "staffName",
              //   `${userResults.firstName} ${userResults.lastName}`
              // );
              sessionStorage.setItem(
                "clinic",
                JSON.stringify(response.data.clinic)
              );
              sessionStorage.setItem("clinicName", response.data.clinic.name);
              sessionStorage.setItem(
                "clinicEmail",
                response.data.clinic.emailAddress
              );
              sessionStorage.setItem("siteName", response.data.clinic.site);

              clinicId = response.data.clinic.id;
              sessionStorage.setItem("clinicId", response.data.clinic.id);
              sessionStorage.setItem(
                "clientId",
                response.data.clinic.client.id
              );
              sessionStorage.setItem(
                "clientCode",
                response.data.clinic.client.code
              );
              sessionStorage.setItem(
                "teleconsults",
                response.data.clinic.acceptsTeleconsults
              );
              sessionStorage.setItem(
                "partnerClinics",
                response.data.partnerClinics
              );

              let userDetails = {
                ...userResults,
                partnerClinics: response.data.partnerClinics,
                acceptsTeleconsults: response.data.clinic.acceptsTeleconsults,
              };
              appContext.setUserDetails(userDetails);

              setLoading(false);

              api
                .get(`/staff?clinic__id=${clinicId}`)
                .then(staffResponse => {
                  sessionStorage.setItem(
                    "clinicStaff",
                    JSON.stringify(staffResponse.data.results)
                  );

                  let staffDetails = staffResponse.data.results.find(
                    staff => staff.emailAddress === userEmail
                  );
                  sessionStorage.setItem(
                    "staffName",
                    `${staffResponse.data.results[0].firstName} ${
                      staffResponse.data.results[0].lastName
                    }`
                  );

                  if (staffDetails) {
                    sessionStorage.setItem(
                      "staffName",
                      `${staffDetails.firstName} ${staffDetails.lastName}`
                    );
                    appContext.setUserDetails({
                      ...userDetails,
                      firstName: staffDetails.firstName,
                      lastName: staffDetails.lastName,
                    });
                  }
                })

                .catch(error => {
                  setLoading(false);
                  setError(true);
                  setErrorMessage(
                    "Account does not exist. Please contact admin for more info."
                  );
                });
              navigate(pathName);
            })
            .catch(error => {
              setLoading(false);
              setError(true);
              setErrorMessage(
                "Account does not exist. Please contact admin for more info."
              );
            });
        }
      })
      .catch(error => {
        setLoading(false);
        setError(true);
        setErrorMessage(error.message);
      });
  };

  useEffect(() => {
    // Remove all session storage keys and their values
    sessionStorage.clear();
    if (appContext) appContext.setUserDetails({ employee: { site: {} } });
    Auth.signOut();
    //
  }, []);

  const [randomPhrase, setRandomPhrase] = useState("");

  useEffect(() => {
    // Generate random phrase on login
    const randomPhrases = [
      "It’s a good day to save lives.",
      "With a great clinic comes great responsibility. Welcome back!",
      "Ready to keep our employees healthy?",
      "Don’t forget to clock in on Deputy!",
      "Look, our favorite nurse is back!",
      "Save one life, you’re a hero. Save a hundred lives, you’re a nurse. Welcome back!",
      "Welcome back! Remember to wash your hands regularly.",
    ];
    const randomIndex = Math.floor(Math.random() * randomPhrases.length);
    setRandomPhrase(randomPhrases[randomIndex]);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        if (view === "logIn") handleAWSLogin(values, location.pathname);
        else if (view === "sendCode") handleSendCode(values.Username);
        else if (view === "resetPassword") handleResetPassword(values);
      }}
      validationSchema={LoginValidationSchema}
      render={props => (
        <Form autoComplete="off">
          {error ? (
            <div className="notification is-danger p-1 my-1 mb-2">
              <div id="errorMessage" className="has-text-white is-size-6">
                {errorMessage}
              </div>
            </div>
          ) : (
            <p className="is-size-5 my-1 mb-2">{randomPhrase}</p>
          )}
          <center>
            {view === "logIn" ? (
              <>
                <div className="has-text-left">
                  <Input
                    label="Email"
                    type="email"
                    name="Username"
                    placeholder="Email"
                    isRequired
                  />
                  <Input
                    label="Password"
                    type="password"
                    name="Password"
                    placeholder="Password"
                    isRequired
                  />
                </div>
                <small>
                  Forgot your password?{" "}
                  <span
                    className="is-link is-primary"
                    type="button"
                    id="resetPasswordButton"
                    onClick={() => setView("sendCode")}
                  >
                    <u>Reset password</u>
                  </span>
                </small>
              </>
            ) : view === "sendCode" ? (
              <>
                <div className="has-text-left">
                  <div
                    className="is-size-7 is-link is-primary mb-1"
                    type="button"
                    id="sendCodeCancelButton"
                    onClick={() => setView("logIn")}
                  >
                    <u>
                      <span className="icon">
                        <FontAwesomeIcon icon={faArrowLeft} />
                      </span>
                      Back to Login
                    </u>
                  </div>
                  <Input
                    label="Enter your email"
                    type="email"
                    name="Username"
                    placeholder="Email"
                    isRequired
                  />
                </div>
              </>
            ) : view === "resetPassword" ? (
              <>
                <div className="has-text-left">
                  <div
                    className="is-size-7 is-link is-primary mb-1"
                    type="button"
                    id="sendCodeCancelButton"
                    onClick={() => setView("logIn")}
                  >
                    <u>
                      <span className="icon">
                        <FontAwesomeIcon icon={faArrowLeft} />
                      </span>
                      Back to Login
                    </u>
                  </div>
                  <Input
                    type="text"
                    value="Username"
                    name="Username"
                    isDisabled
                  />
                  <Input
                    type="text"
                    value="Code"
                    name="Code"
                    placeholder="Verification Code"
                  />
                  <Input
                    type="password"
                    name="NewPassword"
                    placeholder="New Password"
                  />
                </div>
                Didn’t receive code?{" "}
                <span
                  className="is-link is-primary"
                  type="button"
                  onClick={() => handleSendCode(Username)}
                >
                  <u>Resend</u>
                </span>
              </>
            ) : null}
            <button
              type="Submit"
              className={classNames(
                "button mt-3 is-medium is-primary is-fullwidth",
                {
                  "is-loading": isLoading,
                }
              )}
            >
              {view === "logIn"
                ? "Login"
                : view === "sendCode"
                ? "Send Code"
                : view === "resetPassword"
                ? "Reset Password"
                : null}
            </button>
          </center>
        </Form>
      )}
    />
  );
};

export default LoginForm;
