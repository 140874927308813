import React, { useEffect } from "react";
import Select from "react-select";
import classNames from "classnames";

import styles from "./forms.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { ErrorMessage, useField } from "formik";

// see full documentation here: https://github.com/JedWatson/react-select
const Datalist = ({
  name,
  placeholder,
  label,
  options,
  onChange,
  isRequired,
  isLoading,
  defaultValue,
  value,
  isDisabled,
  description,
  errors,
}) => {
  const Label = () => (
    <label>
      {!!label && (
        <span className={classNames({ "has-text-weight-bold": !!isRequired })}>
          {label} {!!isRequired && <span className="has-text-danger">*</span>}
        </span>
      )}
      {description && (
        <span
          className="icon has-text-grey-light mr-1 tooltip tooltip-text is-tooltip-right is-tooltip-multiline"
          data-tooltip={description}
        >
          <FontAwesomeIcon icon={faQuestionCircle} size="1x" />
        </span>
      )}
    </label>
  );

  return (
    <div className="my-1 mb-2">
      <Label />
      <Select
        value={value}
        defaultValue={defaultValue}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        options={options}
        isSearchable
        isLoading={isLoading}
        isDisabled={isDisabled}
        className={classNames(styles.SelectControl)}
        components={{
          IndicatorSeparator: () => null,
        }}
        theme={theme => ({
          ...theme,
        })}
        isRequired={isRequired}
      />
      {!!errors && !!errors[name] && (
        <div className="is-size-7 has-text-danger my-1">{errors[name]}</div>
      )}
    </div>
  );
};

export default Datalist;
