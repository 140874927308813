import React from "react";
import classNames from "classnames";

const Container = ({
  mobile,
  tablet,
  desktop,
  widescreen,
  children,
  isCentered,
}) => {
  return (
    <div
      className={classNames("columns px-1-mobile", {
        "is-centered": isCentered,
      })}
    >
      <div
        className={`column is-${mobile || 12}-mobile is-${tablet ||
          10}-tablet is-${desktop || 10}-desktop is-${widescreen ||
          8}-widescreen`}
      >
        {children}
      </div>
    </div>
  );
};

export default Container;
