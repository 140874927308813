import React, { useState, useEffect, Fragment } from "react";
import api from "../../../services/api";
import Datalist from "../../Templates/Forms/Datalist";
import MedcertLogs from "../../Medcerts/MedcertLogs/MedcertLogs";

const initialClinics = [
  {
    label: "All Clinics",
    value: "",
  },
];

const initialClients = [
  {
    label: "All Clients",
    value: "all",
  },
];

const AdminMedcerts = () => {
  const [options, setOptions] = useState({
    clients: initialClients,
    clinics: initialClinics,
  });
  const [selected, setSelected] = useState({
    client: options.clients[0],
    clinic: options.clinics[0],
  });

  // This useEffect fetches the initial list of clients
  useEffect(() => {
    if (options.clients.length < 2)
      api.get(`clients/?is_active=True`).then(response => {
        let tempClients = initialClients;
        tempClients = tempClients.concat(
          response.data.results.map(item => ({
            label: item.name,
            value: item.code,
          }))
        );
        setOptions({ ...options, clients: tempClients });
      });
  }, [options.clients]);

  // This useEffect fetches the clinics list when the client dropdown value changes
  useEffect(() => {
    if (selected.client !== "all")
      api
        .get(`clinics/?client__code=${selected.client.value}`)
        .then(response => {
          let tempClinics = initialClinics;
          tempClinics = tempClinics.concat(
            response.data.results.map(item => ({
              label: item.name,
              value: item.id,
            }))
          );
          setOptions({ ...options, clinics: tempClinics });
        });
  }, [selected]);

  return (
    <Fragment>
      <div className="columns">
        <div className="column">
          <Datalist
            label="Client"
            hasLabel
            defaultValue={options.clients[0]}
            options={options.clients}
            value={selected.client}
            onChange={value => {
              setSelected({
                ...selected,
                client: value,
                clinic: initialClinics[0],
              });
            }}
          />
        </div>
        <div className="column">
          <Datalist
            label="Clinic"
            hasLabel
            defaultValue={options.clinics[0]}
            options={options.clinics}
            isDisabled={selected.client.value === "all"}
            value={selected.clinic}
            onChange={value => {
              setSelected({ ...selected, clinic: value });
            }}
          />
        </div>
      </div>
      <hr className="mt-1 mb-5" />
      {selected.clinic.value === "" ? (
        <div className="content is-large has-text-centered">
          To view medcert logs, please choose a clinic first.
        </div>
      ) : (
        <MedcertLogs clinicId={selected.clinic.value} isAdmin />
      )}
    </Fragment>
  );
};

export default AdminMedcerts;
